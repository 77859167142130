import React from 'react';
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {useRouter} from "next/router";

const DocumentsSection = () => {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    const handleClick = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <section className={"section section-white"}>
            <h2 className={"section-sm__inner-wrapper"}>{t.documentation.title}</h2>

            <ul className="documentation__list">
                {
                    t.documentation.list.map((item: any, index: number) => {
                        return (
                            <li key={index} className="documentation__item">
                                <div
                                    onClick={() => handleClick(item.file)}
                                    className="documentation__content">
                                    <div className={`documentation__format documentation__${item.format}`}>
                                        {item.format.toUpperCase()}
                                    </div>
                                    <p className="documentation__title">
                                        {item.title}
                                    </p>
                                    <div className="documentation__footer">
                                        <p className="documentation__size">{item.size} {t.other.size}</p>
                                        <a onClick={(e) => {
                                            e.stopPropagation();
                                        }} className="documentation__button" download href={item.file}>{t.btn.download}</a>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    );
};

export default DocumentsSection;