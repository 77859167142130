import React from 'react';
import Image from "next/image";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {TerminalListType} from "../../utils/PropsTypes";

const TerminalSection = () => {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section section-sm"}>
            <h2 className={"section-sm__inner-wrapper"}>{t.terminal.title}</h2>

            <ul className={"terminal-list"}>
                {
                    t.terminal.list && t.terminal.list.map((item: TerminalListType, index: React.Key) =>
                        <li key={index} className={"terminal-list__item"}>
                            <div className={"terminal-item__container"}>
                                <p className={"terminal-list__title"}>
                                    {item.title}
                                </p>
                                <p className={"body-text_white"}>
                                    {item.text}
                                </p>
                            </div>
                        </li>
                    )
                }
            </ul>
            <div
                className={
                    "terminal-list-info section-sm__inner-wrapper"
                }
            >
                <div className={"terminal-list-docs"}>
                    <Image
                        width={416}
                        height={586}
                        src={"/terminal-doc-1.jpg"}
                        alt={"terminal doc"}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open('/terminal-doc-1.pdf');
                        }}
                    />
                    <Image
                        width={416}
                        height={586}
                        src={"/terminal-doc-2.jpg"}
                        alt={"terminal doc 2"}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open('/terminal-doc-2.pdf');
                        }}
                    />
                    <Image
                        width={416}
                        height={586}
                        src={"/terminal-doc-3.jpg"}
                        alt={"terminal doc 3"}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open('/terminal-doc-3.pdf');
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default TerminalSection;