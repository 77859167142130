import React from 'react';
import Image from "next/image";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {ApplicationsListType} from "../../utils/PropsTypes";

const ApplicationScopeSection = () => {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <>
            <h2>{t.applications.title}</h2>
            <ul className={'application-scope'}>
                {
                    t.applications.list && t.applications.list.map((item: ApplicationsListType, index: React.Key) =>
                        <li key={index} className={'application-scope__item'}>
                            <div className={'application-scope__img-container'}>
                                <Image height={46} width={46} src={item.src}/>
                            </div>

                            <div>
                                <p className={'application-scope__title'}>{item.title}</p>
                                <ul className={'sphere-list body-text_gray'}>
                                    {
                                        item.list.map((subItem, index) =>
                                            <li key={index}>{subItem}</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </li>
                    )
                }
            </ul>
        </>
    );
};

export default ApplicationScopeSection;