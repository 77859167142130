import React, {useContext} from 'react';
import Image from "next/image";
import {ModalContext} from "../modalLayout/ModalLayout";
import {PassProps} from "../../utils/PropsTypes";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";


const BannerSection = ({firstSection}: PassProps) => {
    const modal = useContext(ModalContext);
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section"} ref={firstSection}>
            <h1 className={"title mw1024"}>
                {t.header.title}
            </h1>
            <p className={"text-secondary-description mw681"}>
                {t.header.subtitle}
            </p>
            <button
                className={"button_base"}
                onClick={modal.openFormModal}
            >
                {t.btn.getConsultation}
            </button>

            <div className={"company-partner-logos"}>
                {/*<Image*/}
                {/*    width={104}*/}
                {/*    height={20}*/}
                {/*    src={"/ABB-logo.svg"}*/}
                {/*    alt={"akbars logo"}*/}
                {/*/>*/}
                <Image
                    width={120}
                    height={28}
                    src={"/innopolis-logo.svg"}
                    alt={"innopolis logo"}
                />
                <Image
                    width={104}
                    height={24}
                    src={"/it-park-logo.svg"}
                    alt={"it park logo"}
                />
            </div>
        </section>
    );
};

export default BannerSection;