import React, {useContext} from 'react';
import {Divider} from "../Divider/Divider";
import Image from "next/image";
import {ModalContext} from "../modalLayout/ModalLayout";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";

const ControlSection = () => {
    const modal = useContext(ModalContext);
    const router = useRouter();
    const t: any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section section-sm"}>
            <div className={"section-sm__inner-wrapper"}>
                <h2>
                    {t.control.title}
                </h2>
            </div>

            <div className={"control-description"}>
                <div
                    className={
                        "control-description__about section-sm__inner-wrapper"
                    }
                >
                    <p className={"body-text"}>
                        {t.control.text}
                    </p>

                    <button
                        className={"button_base mt40"}
                        onClick={modal.openFormModal}
                    >
                        {t.btn.getConsultation}
                    </button>
                </div>

                <div className={"control-description__capabilities"}>
                    <h3 className={"services__title"}>
                        {t.control.description.title}
                    </h3>
                    <Divider/>

                    <div className={"control-description__capabilities__info"}>
                        <div className={"control-description__man-img-container"}>
                            <Image
                                width={80}
                                height={80}
                                src={"/man-face.svg"}
                                alt={"man face icon"}
                            />
                        </div>

                        <div className={"arrow-img-container"}>
                            <Image
                                width={24}
                                height={24}
                                src={"/arrow-gray-right.svg"}
                                alt={"right icon"}
                            />
                        </div>

                        <div className={"logo-text"}>
                            Face
                            <div className={"logo-img-container"}>
                                <Image
                                    width={32}
                                    height={32}
                                    src={"/logo.svg"}
                                    alt={"logo"}
                                />
                            </div>
                        </div>

                        <div className={"arrow-img-container"}>
                            <Image
                                width={24}
                                height={24}
                                src={"/arrow-gray-right.svg"}
                                alt={"right icon"}
                            />
                        </div>

                        <div className={"capability-list"}>
                            <p>{t.control.description.subtitle}</p>
                            <ul className={"body-text_gray"}>
                                {
                                    t.control.description.list && t.control.description.list.map((item: string, key: React.Key) =>
                                    <li key={key}>{item}</li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ControlSection;