import React from 'react';
import Image from "next/image";
import Link from "next/link";
import {Item, ItemName} from "../../utils/PropsTypes";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";

type Props = {
    item: Item<ItemName>
}
const ServicesItemSection = ({item}:Props) => {
    const router = useRouter();
    const t: any = router.locale === 'en' ? en : ru;

    return (
        <li>
            <div className={"services-section__card"}>
                <p className={"services-logo-text"}>
                    {item.name.nameOne}
                    <Image
                        width={24}
                        height={24}
                        src={item.name.imageUrl}
                        alt={item.name.imageAlt}
                    />
                    {item.name.nameTwo}
                </p>

                <div className={"services__info"}>
                    <div>
                        <h3 className={"services__title"}>
                            {item.title}
                        </h3>

                        <p className={"services__description"}>
                            {item.text}
                        </p>
                    </div>

                    <Link href={item.link}>
                        <a className={"detail-link"}>
                            {t.btn.more}{" "}
                            <Image
                                width={16}
                                height={16}
                                src={"/arrow-long-right.svg"}
                                alt={"right icon"}
                            />
                        </a>
                    </Link>
                </div>
            </div>
        </li>
    );
};

export default ServicesItemSection;