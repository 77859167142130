import React, {useState} from 'react';
import Image from "next/image";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {CompletedProjectListType} from "../../utils/PropsTypes";

const CompletedProjectSection = () => {
    const [isOpenProjectList, toggleProjectList] = useState(false);
    const router = useRouter();
    const t: any = router.locale === 'en' ? en : ru;


    return (
        <section className={"section-after-white"}>
            <h2>{t.completedProject.title}</h2>

            <div className={"completed-project-list-container"}>
                <ul className={"completed-project-list"}>
                    {
                        t.completedProject.list && t.completedProject.list.map((item: CompletedProjectListType, index: React.Key) =>
                            <li key={index} className={"completed-project__item"}>
                                <Image
                                    height={216}
                                    width={636}
                                    src={item.src}
                                    alt={item.alt}
                                />
                                <div
                                    className={"completed-project__description"}
                                >
                                    <p>
                                        {item.text}
                                    </p>
                                </div>
                            </li>
                        )
                    }
                    <li className={"completed-project__item-text"}>
                        {
                            t.completedProject.listText && t.completedProject.listText.map((item: string, index: React.Key) =>
                                <p key={index} className={"body-text"}>
                                    {item}
                                </p>
                            )
                        }
                    </li>
                </ul>

                {/* ПОКАЗАТЬ ЕЩЁ */}

                <TransitionGroup component={null}>
                    {isOpenProjectList && (
                        <CSSTransition
                            in={isOpenProjectList}
                            timeout={300}
                            classNames={"alert"}
                        >
                            <ul className={"completed-project-list"}>
                                <li
                                    className={
                                        "completed-project__item-text"
                                    }
                                >
                                    {t.completedProject.moreListText && t.completedProject.moreListText.map((item: string, index: React.Key) =>
                                        <p key={index} className={"body-text"}>
                                            {item}
                                        </p>
                                    )}
                                </li>
                                {
                                    t.completedProject.moreList && t.completedProject.moreList.map((item: CompletedProjectListType, index: React.Key) =>
                                        <li key={index} className={"completed-project__item"}>
                                            <Image
                                                height={216}
                                                width={636}
                                                src={item.src}
                                                alt={item.alt}
                                            />
                                            <div className={"completed-project__description"}>
                                                <p>{item.text}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>

            <button
                className={"button_outlined block-center"}
                onClick={() => toggleProjectList(!isOpenProjectList)}
            >
                {!isOpenProjectList ? t.btn.showMore : t.btn.hide}
            </button>
        </section>
    )
        ;
};

export default CompletedProjectSection;