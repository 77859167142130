import React, {useState} from 'react';
import cn from "classnames";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {FormData} from "../../utils/PropsTypes";
import {yupResolver} from "@hookform/resolvers/yup";
import {phoneRegExp} from "../../utils/InitialParams";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import * as yup from "yup";

const ContactsFormSection = () => {
    const [loadingForm, setLoadingForm] = useState(false);
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    const schema = yup
        .object({
            name: yup.string().required(t.schema.required),
            email: yup
                .string()
                .email(t.schema.email)
                .required(t.schema.required),
            phone: yup
                .string()
                .required(t.schema.required)
                .matches(
                    phoneRegExp,
                    t.schema.phone
                ),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });
    const submitHandler: SubmitHandler<FieldValues> = async (data) => {
        setLoadingForm(true);

        try {
            await fetch("/api/contact", {
                method: "post",
                body: JSON.stringify(data),
            });
        } catch (e) {
            console.log("error", e);
            setError("message", {
                message: t.form.error,
            });
        }

        setLoadingForm(false);
    };
    return (
        <section className={"section-white"}>
            <h2 className={"contacts-title"} id={"contacts"}>
                {t.contacts.title}
            </h2>
            <form
                className={"form-contacts"}
                onSubmit={handleSubmit(submitHandler)}
            >
                <div className={"form-contacts-input-container"}>
                    <div className={"text-input-container"}>
                        <div>
                            <input
                                {...register("name")}
                                className={cn("text-input", {
                                    "text-input-error": errors.name,
                                })}
                                type={"text"}
                                name={"name"}
                                placeholder={t.contacts.formPlaceholder.name}
                            />
                            {errors.name && (
                                <p className={"error-text"}>
                                    {t.contacts.required}
                                </p>
                            )}
                        </div>

                        <div>
                            <input
                                {...register("email")}
                                className={cn("text-input", {
                                    "text-input-error":
                                    errors.email,
                                })}
                                type={"text"}
                                name={"email"}
                                placeholder={"E-mail*"}
                            />
                            {errors.email && (
                                <p className={"error-text"}>
                                    {t.contacts.required}
                                </p>
                            )}
                        </div>

                        <div>
                            <input
                                {...register("phone")}
                                className={cn("text-input", {
                                    "text-input-error":
                                    errors.phone,
                                })}
                                type={"text"}
                                name={"phone"}
                                placeholder={t.contacts.formPlaceholder.phone}
                            />
                            {errors.phone && (
                                <p className={"error-text"}>
                                    {t.contacts.required}
                                </p>
                            )}
                        </div>
                    </div>
                    <div>
                                    <textarea
                                        {...register("message")}
                                        className={"textarea"}
                                        name={"message"}
                                        placeholder={t.contacts.formPlaceholder.message}
                                    />
                        {errors.message && (
                            <p className={"error-text"}>
                                {t.contacts.required}
                            </p>
                        )}
                    </div>
                </div>

                <p
                    className={
                        "description-form-text description-form-text-wrapper text-center"
                    }
                >
                    {t.contacts.description}
                </p>

                <button
                    className={"button_base block-center"}
                    disabled={loadingForm}
                >
                    {t.btn.send}
                </button>
            </form>
        </section>
    );
};

export default ContactsFormSection;