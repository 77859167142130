import React from 'react';
import Image from "next/image";
import Link from "next/link";
import ServicesItemSection from "./ServicesItemSection";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {useRouter} from "next/router";
import {Item, ItemName} from "../../utils/PropsTypes";

const ServicesSection = () => {
    const router = useRouter();
    const t: any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section section-sm"}>
            <h2 className={"section-sm__inner-wrapper"}>
                {t.services.title}
            </h2>
            <div className={"services-section__main"}>
                <div className={"bg-terminal"}>
                    <div className={"section-logo-text_white"}>
                        Face
                        <div
                            className={
                                "services__section-logo-container"
                            }
                        >
                            <Image
                                width={32}
                                height={32}
                                src={"/face2greenLogo.svg"}
                                alt={"face 2 logo"}
                            />
                        </div>
                        Pass
                    </div>

                    <h3 className={"services-title_main_white"}>
                        {t.services.subtitle}
                    </h3>
                    <p
                        className={
                            "services-description_main_white"
                        }
                    >
                        {t.services.text}
                    </p>
                    <Link href={"/pass"}>
                        <a className={"detail-link"}>
                            {t.btn.more}
                            {" "}
                            <Image
                                width={16}
                                height={16}
                                src={"/arrow-long-right.svg"}
                                alt={"right icon"}
                            />
                        </a>
                    </Link>

                    <ul className={"benefits-list"}>
                        {t?.services?.benefitsList && t.services.benefitsList.map((item: { text: string }, index: React.Key) =>
                            <li key={index} className={"benefits-list__item"}>
                                {item.text}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <ul className={"services"}>

                {t?.services?.servicesList && t.services.servicesList.map((item:Item<ItemName>, index: React.Key) =>
                    <ServicesItemSection key={index} item={item}/>)
                }
            </ul>
        </section>
    );
};

export default ServicesSection;