import React, {useContext} from 'react';
import {ModalContext} from "../modalLayout/ModalLayout";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";

const PurchaseOptionsSection = () => {
    const modal = useContext(ModalContext);
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section section-sm"}>
            <h2
                className={
                    "purchase-options section-sm__inner-wrapper"
                }
            >
                {t.purchase.title}
            </h2>

            <div className={"acquisition-options"}>
                <div className={"section-sm__inner-wrapper"}>
                    <h3 className={"services__title"}>
                        {t.purchase.oneTimePayment.title}
                    </h3>
                    <p className={"body-text"}>
                        {t.purchase.oneTimePayment.text}
                    </p>
                </div>

                <div className={"section-sm__inner-wrapper"}>
                    <h3 className={"services__title"}>
                        {t.purchase.serviceModel.title}
                    </h3>
                    <p className={"body-text"}>
                        {t.purchase.serviceModel.text}
                    </p>
                </div>

                <div className={"acquisition-options__card"}>
                    <h3 className={"services__title doc-title"}>
                        {t.purchase.description}
                    </h3>

                    <button
                        className={"button_base"}
                        onClick={modal.openFormModal}
                    >
                        {t.btn.getConsultation}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default PurchaseOptionsSection;