import type {NextPage} from "next";
import "@abdt/fonts";
import {useEffect, useRef} from "react";
import {startMainPageOrbAnimation} from "../helpers";
import {Divider} from "../components/Divider/Divider";
import Header from "../components/Header/Header";
import ApplicationScopeSection from "../components/ApplicationScopeSection/ApplicationScopeSection";
import Footer from "../components/Footer/Footer";
import BannerSection from "../components/MainComponents/BannerSection";
import ServicesSection from "../components/MainComponents/ServicesSection";
import ControlSection from "../components/MainComponents/ControlSection";
import TerminalSection from "../components/MainComponents/TerminalSection";
import PartnersSection from "../components/MainComponents/PartnersSection";
import PurchaseOptionsSection from "../components/MainComponents/PurchaseOptionsSection";
import CompletedProjectSection from "../components/MainComponents/CompletedProjectSection";
import ContactsFormSection from "../components/MainComponents/ContactsFormSection";
import DocumentsSection from "../components/MainComponents/DocumentsSection";


const Home: NextPage = () => {
    const firstSection = useRef(null);

    useEffect(() => {
        startMainPageOrbAnimation();
    }, []);

    return (
        <div className={"home"}>
            <Header firstSection={firstSection}/>
            <main>
                <BannerSection firstSection={firstSection}/>
                <ServicesSection/>
                <ControlSection/>
                {/*<AboutSection/>*/}
                <TerminalSection/>
                <div className={"bg-white"}>
                    <PartnersSection/>
                    <DocumentsSection/>
                    <PurchaseOptionsSection/>
                    <section className={"section-white-end"}>
                        <ApplicationScopeSection/>
                    </section>
                </div>
                <CompletedProjectSection/>
                <div className={"bg-white"}>
                    <ContactsFormSection/>
                    <Divider margin={0}/>
                </div>
            </main>
            <div className={"bg-gray"}>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;
